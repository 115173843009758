import { jsx as _jsx } from "react/jsx-runtime";
import { Column, Table2 } from '@blueprintjs/table';
import styles from './PciTableView.module.scss';
const cols = [
    'owner_id',
    'section_id',
    'road_name',
    'conventional_rating',
    'pci_rating',
    'length~meters',
    'area~meters',
    'length~feet',
    'area~feet',
    'pothole_LOW',
    'pothole_MODERATE',
    'pothole_HIGH',
    'pothole_OVERALL',
    'crack_alligator_LOW',
    'crack_alligator_MODERATE',
    'crack_alligator_HIGH',
    'crack_alligator_OVERALL',
    'surface_deform_LOW',
    'surface_deform_MODERATE',
    'surface_deform_HIGH',
    'surface_deform_OVERALL',
    'surface_defect_LOW',
    'surface_defect_MODERATE',
    'surface_defect_HIGH',
    'surface_defect_OVERALL',
    'patch_LOW',
    'patch_MODERATE',
    'patch_HIGH',
    'patch_OVERALL',
    'crack_len_LOW',
    'crack_len_MODERATE',
    'crack_len_HIGH',
    'crack_len_OVERALL',
    'nondefect_LOW',
    'nondefect_MODERATE',
    'nondefect_HIGH',
    'nondefect_OVERALL',
    'other_cracks_LOW',
    'other_cracks_MODERATE',
    'other_cracks_HIGH',
    'other_cracks_OVERALL',
    'geometry',
    'platform_urls',
    'frames_frontal',
    'frames_bev',
];
export const PciTableView = ({ data }) => {
    const basicCellRenderer = (rowIndex, columnIndex) => {
        const row = data.rows[rowIndex];
        // console.log({ rowIndex, row, data });
        return row
            ? _jsx("div", { className: styles.cell, children: row[cols[columnIndex]] })
            : undefined;
    };
    return (_jsx(Table2, { numRows: 30, children: cols
            .map((el) => (_jsx(Column, { name: el, cellRenderer: basicCellRenderer }, el))) }));
};

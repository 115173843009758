import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { LoadingStatus } from '../../../const/appConstants';
import { RoadLyMapContext } from '../../../contexts/roadLyMapContext';
import { LoadingOverlay } from '../../../components';
import { apiAsClass } from '../../../services/ApiService';
import { ProfileContext } from '../../../contexts/profileContext';
import { asAuthorizedUser } from '../../../services/ApiService/normalizeUser';
import { PciTableView } from './PciTableView';
export const PciTableViewContainer = () => {
    const [roadLyMapContext] = useContext(RoadLyMapContext);
    const [profile] = useContext(ProfileContext);
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.LOADING);
    const [data, setData] = useState({ rows: [] });
    useEffect(() => {
        const effect = async () => {
            setLoadingStatus(LoadingStatus.LOADING);
            const newData = await apiAsClass.exportBoundedByViewport({
                boundingBox: roadLyMapContext.boundingBox,
                organizationId: asAuthorizedUser(profile).organization_id,
            });
            setData(newData);
            setLoadingStatus(LoadingStatus.SUCCESS);
        };
        effect();
    }, []);
    return (_jsxs(_Fragment, { children: [loadingStatus === LoadingStatus.LOADING ? _jsx(LoadingOverlay, {}) : null, loadingStatus === LoadingStatus.SUCCESS ? _jsx(PciTableView, { data: data }) : null, loadingStatus === LoadingStatus.ERROR ? 'Error' : null] }));
};

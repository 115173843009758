import { pciFeatureProcessingStatuses } from '../../const/pciConstants';
import { asAuthorizedUser } from './normalizeUser';
export const addSuperAdminPciSearchParams = (searchParams) => {
    // searchParams.append('return_statuses', 'processed'); // Old
    searchParams.append('return_statuses', pciFeatureProcessingStatuses.valid); // New
    searchParams.append('return_statuses', pciFeatureProcessingStatuses.invalid);
    searchParams.append('return_statuses', pciFeatureProcessingStatuses.hidden);
};
export const addOperatorPciSearchParams = (searchParams) => {
    // searchParams.append('return_statuses', 'processed'); // Old
    searchParams.append('return_statuses', pciFeatureProcessingStatuses.valid); // New
    searchParams.append('return_statuses', pciFeatureProcessingStatuses.invalid);
};
class ApiHelpers {
    constructor() {
        this.getProfile = () => {
            throw new Error('apiHelpers: Default getProfile method');
        };
        this.setProfile = () => {
            throw new Error('apiHelpers: Default setProfile method');
        };
    }
    initProfileGetterAndSetter({ getProfile, setProfile }) {
        this.getProfile = getProfile;
        this.setProfile = setProfile;
    }
    getProfileAsAuthorized() {
        return asAuthorizedUser(this.getProfile());
    }
}
export const apiHelpers = new ApiHelpers();
export const makeAreaPolygonRequestOptions = ({ options, boundingBox }) => ({
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
        geom_filter: {
            type: 'Polygon',
            coordinates: [boundingBox],
        },
        geom_relation: 'ST_INTERSECTS',
    }),
});

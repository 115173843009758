import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Callout, Classes, Dialog, Intent, Radio, RadioGroup } from '@blueprintjs/core';
import { clsx } from 'clsx';
import { Divider } from '@blueprintjs/core/lib/esm/components';
import { LoadingStatus } from '../../../const/appConstants';
import { ExportIcon } from '../../../components/icons';
import { pciExportTypeFormatsArray } from '../../../const/pciConstants';
import { LoadingOverlay } from '../../../components';
import styles from './ExportPciWithOptions.module.scss';
export const ExportPciWithOptions = ({ exportLoadingStatus, onClickExport, }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => setIsModalVisible(true);
    const closeModal = () => setIsModalVisible(false);
    const [selectedFormat, setSelectedFormat] = useState(pciExportTypeFormatsArray[0]);
    const handleClick = async () => {
        await onClickExport(selectedFormat);
        closeModal();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Button, { minimal: true, onClick: showModal, className: styles.button, children: [_jsx(ExportIcon, {}), "Export"] }), _jsx(Dialog, { isOpen: isModalVisible, onClose: closeModal, usePortal: true, canOutsideClickClose: true, children: _jsxs("div", { className: styles.dialogContainer, children: [exportLoadingStatus === LoadingStatus.LOADING ? _jsx(LoadingOverlay, {}) : null, _jsxs("div", { className: Classes.DIALOG_HEADER, children: [_jsx("h5", { className: "bp4-heading", children: "Export" }), _jsx(Button, { icon: "cross", className: clsx(Classes.DIALOG_CLOSE_BUTTON, styles.closeButton), onClick: closeModal })] }), _jsxs("div", { className: Classes.DIALOG_BODY, children: [_jsx("div", { className: styles.body, children: _jsx(Callout, { icon: "export", title: "PCI Export", children: "Choose file format to export your PCI statistics" }) }), _jsx("div", { children: _jsx(RadioGroup, { label: "Export file format:", name: "group", inline: true, onChange: (event) => {
                                            console.log('event', event);
                                            console.log('event.target', event.target);
                                            // @ts-ignore
                                            setSelectedFormat(event.target.value);
                                        }, selectedValue: selectedFormat, children: pciExportTypeFormatsArray.map((format) => (_jsx(Radio, { label: format, value: format }, format))) }) })] }), _jsx(Divider, {}), _jsx("div", { className: Classes.DIALOG_FOOTER, children: _jsx("div", { className: Classes.DIALOG_FOOTER_ACTIONS, children: _jsx(Button, { intent: Intent.PRIMARY, className: styles.button, onClick: handleClick, children: "Download" }) }) })] }) })] }));
};

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import axios from 'axios';
import { REQUEST_STATUS } from '../../const/appConstants';
import { normalizers, normalizePciAreaResponse, appConfigurationNormalizer } from '../../utils/normalizers';
import { apiClient } from './ApiClient';
import { normalizeUser } from './normalizeUser';
import { handles } from './apiHandles';
import { requestResetPasswordNormalizer } from '../../utils/normalizers/password/RequestResetPasswordNormalizer';
import { manuallyChangePasswordNormalizer } from '../../utils/normalizers/password/ManuallyChangePasswordNormalizer';
import { pciQaNormalizer } from '../../utils/normalizers/PciQaNormalizer';
import { organizationSessionsDistanceNormalizer } from '../../utils/normalizers/organizationSessionsDistanceNormalizer';
import { apiCacheKeys, cacheApiMethod } from '../ApiCacheService/ApiCacheService';
import { pciExportTypeExtensionsByFormat, pciExportTypeFormats, pciExportTypeHeadersByFormat, } from '../../const/pciConstants';
import { downloadBlob, downloadString } from '../../utils/download';
import { apiHelpers, makeAreaPolygonRequestOptions } from './api.helpers';
import { staticServerClient } from './StaticServer.client';
const options = { mode: 'cors', credentials: 'include' };
const patchOptions = {
    ...options,
    method: 'PATCH',
    headers: {
        'Content-Type': 'application/json',
    },
};
export const API = {
    whoami: async () => {
        try {
            const data = await apiClient.fetch(handles.whoami(), options);
            const json = await data.json();
            if (data.status <= 200) {
                return normalizeUser(json);
            }
            return normalizeUser();
        }
        catch (e) {
            return normalizeUser();
        }
    },
    signUp: async ({ email, organization_name }) => {
        try {
            const data = await apiClient.fetch(handles.signUp(), {
                ...options,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, organization_name }),
            });
            switch (data === null || data === void 0 ? void 0 : data.status) {
                case 200:
                    return { status: REQUEST_STATUS.OK };
                // Todo: clarify format of browser response
                case 400:
                    return {
                        status: REQUEST_STATUS.DUPLICATED,
                        details: [{ loc: 'Email', msg: 'User exists' }],
                    };
                case 422:
                    return {
                        status: REQUEST_STATUS.NOT_VALID,
                        details: [{ loc: 'Request', msg: 'Invalid request' }],
                    };
                case 500:
                    return {
                        status: REQUEST_STATUS.INTERNAL_ERROR,
                        details: [{ loc: 'Email', msg: 'User exists' }],
                    };
                default:
                    return {
                        status: REQUEST_STATUS.UNKNOWN_ERROR,
                        details: [{ loc: 'Server', msg: 'Unknown error' }],
                    };
            }
        }
        catch (e) {
            console.error('Caught error', e);
            // Todo: check if status has to be wrapped
            //  with object as in error statuses above
            return REQUEST_STATUS.UNKNOWN_ERROR;
        }
    },
    confirmCode: async ({ code, email }) => {
        const data = await apiClient.fetch(handles.confirmCode({ code, email }), {
            ...options,
            method: 'GET',
        });
        switch (data === null || data === void 0 ? void 0 : data.status) {
            case 200:
                return { status: REQUEST_STATUS.OK };
            case 404:
                return { status: REQUEST_STATUS.NOT_FOUND };
            default:
                return { status: REQUEST_STATUS.UNKNOWN_ERROR };
        }
    },
    manuallyChangePassword: async ({ password, newPassword }) => {
        const response = await apiClient.fetch(handles.manuallyChangePassword(), {
            method: 'POST',
            cache: 'no-cache',
            ...options,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                password,
                password_new: newPassword,
            }),
        });
        return manuallyChangePasswordNormalizer.normalizeResponse(response);
    },
    // Automatic reset with new password generated
    requestResetPassword: async ({ email }) => {
        const response = await apiClient.fetch(handles.requestResetPassword(), {
            method: 'POST',
            cache: 'no-cache',
            ...options,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
        });
        return requestResetPasswordNormalizer.normalizeResponse(response);
    },
    // Todo: replace unknown
    login: async (params) => {
        try {
            const data = await apiClient.fetch(handles.login(), {
                method: 'POST',
                cache: 'no-cache',
                // mode: 'no-cors',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(params),
            });
            const json = await data.json();
            if (data.status <= 200) {
                return normalizeUser(json);
            }
            return normalizeUser();
        }
        catch (e) {
            return normalizeUser();
        }
    },
    // Todo: replace unknown
    acceptInvite: async (params) => {
        try {
            const json = await axios.post(handles.acceptInvite(), params);
            return {
                status: 'ok',
                data: normalizeUser(json.data),
            };
        }
        catch (err) {
            return {
                status: 'error',
                data: err,
            };
        }
    },
    logout: async () => {
        await apiClient.fetch(handles.logout(), {
            method: 'POST',
            cache: 'no-cache',
            mode: 'no-cors',
        });
        return normalizeUser();
    },
    getPipelineListShort: async (params) => {
        const data = await apiClient.fetch(handles.pipelineListShort(params), options);
        const json = await data.json();
        return normalizers.pipelineNormalizer.normalizeGetPipelineListResponseShort(json);
    },
    getSessionList: async (params) => {
        const data = await apiClient.fetch(handles.sessionList(params), options);
        const json = await data.json();
        return normalizers.sessionNormalizer.normalizeGetSessionListResponse(json);
    },
    runPipelineItem: async ({ payload }) => {
        return apiClient.fetch(handles.runPipelineItem(), {
            ...options,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: payload,
        });
    },
    updatePipelineName: async (uuid, payload) => {
        await apiClient.fetch(handles.updatePipelineName(uuid), {
            ...options,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });
    },
    runChunkedPipelineItem: async ({ payload }) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { session_id, data } = payload;
        return apiClient.fetch(handles.runChunkedPipelineItem(), {
            ...options,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...data, session_id }),
        });
    },
    runMiniPCIPipelineItem: async ({ payload }) => {
        const { sessionId, roadGraph } = payload;
        return apiClient.fetch(handles.runMiniPCIPipelineItem(), {
            ...options,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ session_id: sessionId, road_graph: roadGraph }),
        });
    },
    runRelocPipelineItem: async (payload) => {
        return apiClient.fetch(handles.runRelocPipelineItem(), {
            ...options,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });
    },
    runTilingPipelineItem: async (payload) => {
        return apiClient.fetch(handles.runTilingPipelineItem(), {
            ...options,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });
    },
    runGaussPipelineItem: async (payload) => {
        return apiClient.fetch(handles.runGaussPipelineItem(), {
            ...options,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });
    },
    runCamLocPipelineItem: async ({ imageFile, kmlFile, reconstruction, useGpsBasedSelection, skipGpsForSelection, referencePipelineUUID }) => {
        const formData = new FormData();
        formData.append('imageFile', imageFile);
        if (kmlFile) {
            formData.append('kmlFile', kmlFile);
        }
        const queryParams = Object
            .fromEntries(Object
            .entries({ reconstruction, useGpsBasedSelection, skipGpsForSelection, referencePipelineUUID })
            .filter((el) => el[1] !== null));
        return apiClient.fetch(handles.runCamLocPipelineItem(queryParams), {
            method: 'POST',
            body: formData,
        });
    },
    changePipelineItemPublished: async (payload) => {
        const { uuid, data } = payload;
        await apiClient.fetch(handles.changePipelineItemPublished(uuid), {
            ...options,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });
        return {};
    },
    getPipelineItem: async (params) => {
        const data = await apiClient.fetch(handles.pipelineItem(params), options);
        const [json] = await data.json();
        return normalizers.pipelineNormalizer.normalizeGetPipelineItemResponse(json);
    },
    getPipelineOutputLog: async (url, params) => {
        const data = await apiClient.fetch(url, {
            method: 'POST',
            mode: 'no-cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        });
        const [json] = await data.json();
        return json;
    },
    cancelPipeline: async (uuid) => {
        const data = await apiClient.fetch(handles.pipelineItemAction({ uuid, action: 'cancel' }), {
            ...options,
            method: 'POST',
        });
        const json = await data.json();
        return json;
    },
    resumePipeline: async (uuid) => {
        const data = await apiClient.fetch(handles.pipelineItemAction({ uuid, action: 'resume' }), {
            ...options,
            method: 'POST',
        });
        const json = await data.json();
        return json;
    },
    deletePipeline: async (uuid) => {
        const data = await apiClient.fetch(handles.pipelineItemAction({ uuid, action: 'delete' }), {
            ...options,
            method: 'POST',
        });
        const json = await data.json();
        return json;
    },
    rerunPipelineItem: async ({ pipeline_uuid, payload }) => {
        const data = await apiClient.fetch(handles.rerunPipelineItem({ pipeline_uuid }), {
            ...options,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: payload,
        });
        const json = await data.json();
        return json;
    },
    getPayloadJson: async () => {
        const data = await apiClient.fetch(handles.getPayloadJson(), options);
        const json = await data.json();
        return json;
    },
    // Todo: replace unknown
    getInvites: async (searchParams) => {
        const data = await apiClient.fetch(handles.getInvites(searchParams), options);
        const json = await data.json();
        return json;
    },
    // Todo: replace unknown
    createInvite: async (formData) => {
        const data = await apiClient.fetch(handles.createInvite(), {
            ...options,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
        const json = await data.json();
        return { status: data.status, data: json };
    },
    getModules: async () => {
        const data = await apiClient.fetch(handles.getModules());
        const json = await data.json();
        return json;
    },
    getEmails: async (organization_id) => {
        const data = await fetch(handles.getEmails(organization_id));
        const json = await data.json();
        return json;
    },
    getOrganizations: async () => {
        const data = await fetch(handles.getOrganizations());
        const json = await data.json();
        return json;
    },
    getReconstructions: async () => {
        const data = await fetch(handles.getReconstructions());
        const json = await data.json();
        return json;
    },
    getMiniPCIRoadGraphs: async () => {
        const data = await fetch(handles.getMiniPCIRoadGraphs());
        const json = await data.json();
        return json;
    },
    getSlamChoiches: async () => {
        const data = await fetch(handles.getSlamChoiches());
        const json = await data.json();
        return json;
    },
    getPipelineTypes: async () => {
        const data = await fetch(handles.getPipelineTypes());
        const json = await data.json();
        return json;
    },
    getMiniPciFrameSegment: async (logicalId) => {
        const { isStaticUser } = apiHelpers.getProfileAsAuthorized();
        const data = isStaticUser
            ? await staticServerClient.fetch(`/pci/frame-segment/${logicalId}`)
            : await apiClient.fetch(handles.getMiniPciFrameSegment(logicalId));
        const json = await data.json();
        return normalizers.pciFrameSegmentNormalizer.normalize(json);
    },
    /*
     * PCI QA SESSION STUFF
     */
    getPciQaSessionFrameSegment: async (frameSegmentId) => {
        const response = await apiClient.fetch(handles.getPciQaSessionFrameSegment(frameSegmentId));
        return pciQaNormalizer.normalizeGetFrameSegmentResponse(response);
    },
    getPciQaSessionStats: async (pipelineId) => {
        const response = await apiClient.fetch(handles.getPciQaSessionStats(pipelineId));
        return pciQaNormalizer.normalizeGetPciQaSessionStatsResponse(response);
    },
    patchPciQaSessionStats: async (params) => {
        const response = await apiClient.fetch(handles.patchPciQaSessionStats(params), {
            ...options,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
        }); // Todo: substitute object with patchOptions and test
        return pciQaNormalizer.normalizePatchFrameSegmentResponse(response);
    },
    exportPciQaSession: async (pipelineId) => {
        const response = await apiClient.fetch(handles.exportPciQaSession(pipelineId));
        return pciQaNormalizer.normalizeExportPciQaSessionResponse(response);
    },
    changeFrameSegmentStatus: async (frameSegmentId, patchBody) => {
        const response = await apiClient.fetch(handles.changeFrameSegmentStatus(frameSegmentId, patchBody), {
            ...patchOptions,
            body: JSON.stringify(patchBody),
        });
        // Nothing returned as json
        // const data = await response.json();
        // console.log('data', data);
        // return data;
    },
    /*
     * END OF PCI QA SESSION STUFF
     */
    getSignedUrl: async (params) => {
        const data = await apiClient.fetch(handles.getSignedUrl(params));
        const text = await data.text();
        return text;
    },
    getAppConfiguration: async () => {
        const data = await apiClient.fetch(handles.getAppConfiguration());
        const json = await data.json();
        return appConfigurationNormalizer(json);
    },
    restorePassword2Stage: async (code, password) => {
        const data = await apiClient.fetch(handles.restorePassword2Stage(), {
            ...options,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code,
                password,
            }),
        });
        return !!data;
    },
    runSignsAssetsPipeline: async (sessionId, pointcloudPayload = { data: {} }) => {
        const response = await apiClient.fetch(handles.runSignsAssetsPipeline(), {
            ...options,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ session_id: sessionId, ...pointcloudPayload.data }),
        });
        return response;
        // Todo: define common normalizer for assets
    },
    runAssetsPipeline: async ({ sessionId, textPrompt, boxThreshold, textThreshold, textPromptSizes, areSizesUsed, pointcloudData = {} }) => {
        const bodyObject = {
            session_id: sessionId,
            sam_type: 'vit_l',
            text_prompt: textPrompt,
            scale_x: 0.5,
            scale_y: 0.5,
            ...((boxThreshold !== undefined) ? { box_threshold: boxThreshold } : {}),
            ...((textThreshold !== undefined) ? { text_threshold: textThreshold } : {}),
            lsa_frames_per_chunk: -1,
            ...pointcloudData,
        };
        if (areSizesUsed) {
            bodyObject.text_prompt_sizes = textPromptSizes;
        }
        const response = await apiClient.fetch(handles.runAssetsPipeline(), {
            ...options,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(bodyObject),
        });
        return response;
    },
    getOrganizationSessionsDistance: async () => {
        const response = await apiClient.fetch(handles.getOrganizationSessionsDistance());
        return organizationSessionsDistanceNormalizer.normalize(response);
    },
    // simpleExportPciData: async (
    //   organizationId: number,
    //   format: PciExportTypeFormat = pciExportTypeFormats.csv,
    // ) => {
    //   const response = await apiClient.fetch(
    //     handles.simpleExportPciData(organizationId),
    //     { headers: {
    //       accept: pciExportTypeHeadersByFormat[format],
    //     } },
    //   );
    //   return response.text();
    // },
    simpleExportPciData: async (organizationId, format = pciExportTypeFormats.csv) => {
        const response = await apiClient.fetch(handles.simpleExportPciData(organizationId), { headers: {
                accept: pciExportTypeHeadersByFormat[format],
            } });
        const filename = `pci_export.${pciExportTypeExtensionsByFormat[format]}`;
        if ([
            pciExportTypeFormats.json,
            pciExportTypeFormats.geoJson,
        ].includes(format)) {
            downloadString(filename, await response.text());
            return;
        }
        downloadBlob(filename, await response.blob());
        // const blob = await response.blob();
        // const file = window.URL.createObjectURL(blob);
        // window.location.assign(file);
    },
    getFinalAssets: async () => {
        const response = await apiClient.fetch(handles.getFinalAssets());
        return response.json();
    },
    getFinalAssetsForQa: async () => {
        const response = await apiClient.fetch(handles.getFinalAssetsForQa());
        return response.json();
    },
    updateFinalAsset: async (feature) => {
        const config = {
            ...options,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        if (feature.properties.is_approved === true) {
            config.body = JSON.stringify({
                type: 'Point',
                coordinates: [
                    `${feature.geometry.coordinates[0]}`,
                    `${feature.geometry.coordinates[1]}`,
                    `${feature.geometry.coordinates[2]}`,
                ],
                bbox: null,
            });
        }
        const data = await apiClient.fetch(handles.updateFinalAsset(feature), config);
    },
};
// Introduced as class to be able to decorate its methods
class ApiAsClass {
    // @cacheApiMethod(apiCacheKeys.extendedSessions)
    async getExtendedSessions(params) {
        const data = await apiClient.fetch(handles.getExtendedSessions(params));
        const result = await data.json();
        return result;
    }
    // @cacheApiMethod(apiCacheKeys.getSessions)
    async getSessions(organization_id) {
        const data = await apiClient.fetch(handles.getSessions(organization_id), options);
        const json = await data.json();
        return json;
    }
    // @cacheApiMethod(apiCacheKeys.pciMapData)
    async getWholePciRegistry({ pipelineId, asSuperAdmin }) {
        const response = await apiClient.fetch(handles.getWholePciRegistry({ pipelineId, asSuperAdmin }));
        const json = await response.json();
        return normalizePciAreaResponse(json);
    }
    async getStaticPciRegistryArea(organization_id) {
        const response = await staticServerClient.fetch(`/pci/area?organizationId=${organization_id}`);
        const json = await response.json();
        return normalizePciAreaResponse(json);
    }
    async exportBoundedByViewport({ boundingBox, organizationId }) {
        const requestOptions = makeAreaPolygonRequestOptions({ options, boundingBox: boundingBox || [] });
        requestOptions.headers.accept = pciExportTypeHeadersByFormat[pciExportTypeFormats.json];
        const data = await apiClient.fetch(handles.exportBoundedByViewport(organizationId), requestOptions);
        const json = await data.json();
        return json;
    }
    // @cacheApiMethod(apiCacheKeys.pciMapData)
    async getPciRegistryArea({ pipelineId, boundingBox, asSuperAdmin }) {
        try {
            const data = await apiClient.fetch(handles.getPciRegistryArea({ pipelineId, asSuperAdmin }), makeAreaPolygonRequestOptions({ options, boundingBox: boundingBox || [] }));
            const json = await data.json();
            return normalizePciAreaResponse(json);
        }
        catch (e) {
            console.error('Area request failed', { pipelineId, boundingBox });
            throw e;
        }
    }
    // @cacheApiMethod(apiCacheKeys.getPipelinesList)
    async getPipelineList(params) {
        const data = await apiClient.fetch(handles.pipelineList(params), options);
        const json = await data.json();
        return normalizers.pipelineNormalizer.normalizeGetPipelineListResponse(json);
    }
    async getRawToFinalAssetsMapping(params) {
        const data = await apiClient.fetch(handles.getRawToFinalAssetsMapping(params));
        const json = await data.json();
        return json;
    }
}
__decorate([
    cacheApiMethod(apiCacheKeys.staticPciMapData),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", Promise)
], ApiAsClass.prototype, "getStaticPciRegistryArea", null);
export const apiAsClass = new ApiAsClass();
